<template>
  

  <div class="mx">
  
    Page in progress

  </div>
  
</template>

<script>
export default {
  // middleware: 'guest',
  
  data() {
    return {      
    
    }
  }, 
  head() {
    return this.$head.get({})    
  },
  created(){
    
  },
  methods: {
      
  }
}
</script>



<style scoped>


.mx{
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
}
 

</style>
